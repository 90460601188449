//menu button

.bc_btn-nav {
	width: 60px;
	height: 60px;
	top: 16px;
	left: 0;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	padding: 0;
  position: fixed;
  z-index: 1000;
}
.bc_btn-nav span {
	position: absolute;
	width: 30px;
	height: 4px;
	top: 30px;
	left: 38px;
	background: $dark;
	border-radius: 0;
	overflow: hidden;
	transition: all 0.3s linear;
}
  .bc_btn-nav span::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    top: 0;
    right: 0;
    background: $dark;
    transition: all 0.3s linear;
  }
  .bc_btn-nav span:nth-child(1) {
    animation: span-first-off 0.5s ease-in-out;
    animation-fill-mode: forwards;
  }
  .bc_btn-nav span:nth-child(2) {
    animation: span-second-off 0.5s ease-in-out;
    animation-fill-mode: forwards;
  }
  .bc_btn-nav span:nth-child(3) {
    animation: span-third-off 0.5s ease-in-out;
    animation-fill-mode: forwards;
    width: 20px;
    left: 33px;
  }
  .bc_btn-nav.close span:nth-child(1) {
    animation: span-first-on 0.5s ease-in-out;
    animation-fill-mode: forwards;
  }
  .bc_btn-nav.close span:nth-child(2) {
    animation: span-second-on 0.5s ease-in-out;
    animation-fill-mode: forwards;
  }
  .bc_btn-nav.close span:nth-child(3) {
    animation: span-third-on 0.5s ease-in-out;
    animation-fill-mode: forwards;
    width: 30px;
    left: 38px;
  }
  
  @keyframes span-first-on {
    0% {
      transform: translate(-50%, -300%);
    }
    30% {
      transform: translate(-50%, -50%);
    }
    100% {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
  @keyframes span-first-off {
    0% {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
    30% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -300%);
    }
  }
  @keyframes span-second-on {
    0% {
      transform: translate(-50%, -50%);
    }
    25% {
      background: gray;
    }
    50% {
      transform: translate(-50%, -50%) scale(1);
    }
    100% {
      transform: translate(-150%, -50%) scale(0);
    }
  }
  @keyframes span-second-off {
    0% {
      transform: translate(-150%, -50%) scale(0);
    }
    25% {
      background: gray;
    }
    50% {
      transform: translate(-50%, -50%) scale(1);
    }
    100% {
      transform: translate(-50%, -50%);
    }
  }
  @keyframes span-third-on {
    0% {
      transform: translate(-50%, 200%);
    }
    30% {
      transform: translate(-50%, -50%);
    }
    100% {
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
  @keyframes span-third-off {
    0% {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    30% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, 200%);
    }
  }
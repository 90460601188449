.vdr_active{
    color: $themeColor;
    &:hover{
        color: $themeColor;
    }
}

.vdr_theme-link{
    color: $themeColor;
    text-decoration: underline;
    font-weight: 700;
    &:hover{
        color: $themeColor;
    }
}
.vdr_no-text-decoration{
    li{
        list-style: none;
    }
}
.vdr_widescreen{
    display:none;
    @media(min-width: $laptopMin){
        display: block;
    }
}
.vdr_mobile{
    @media(min-width: $tabletMin){
        display: none;
    }
}
.vdr_flex-start{
    display: flex;
    justify-content: flex-start;
}
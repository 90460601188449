//font
$themeFont: 'Montserrat', sans-serif;

//color
$themeColor: #52A145;
$veryDark: #3A3E4A;
$dark: #454955;
$light: #8E98A7;
$white: #FFFFFF;
$veryLight: #EFEFEF;

//media queries
$tabletMax: 767px;
$tabletMin: 768px;
$laptopMax: 991px;
$laptopMin: 992px;
$desktopMax: 1199px;
$desktopMin: 1200px;
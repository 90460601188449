body{
    font-family: $themeFont;
    font-size: 100%;
    color: $dark;
}

#root{
    width: 100%;
    overflow: hidden;
}

h3{
    font-size: 20px;
    font-weight: 700;
}

h4{
    font-size: 16px;
    font-weight: 700;
}

h2{
    font-weight: 700;
    font-size: 30px;
    @media (min-width: $laptopMin){
        font-size: 40px;
    }
}

h1{
    font-size: 30px;
    font-weight: 700;
    @media (min-width: $laptopMin){
        font-size: 40px;
    } 
}

p{
    font-size: 16px;
    font-weight: 300;
    line-height: 1.6285em;
}

a{
    color: inherit;
    &:hover{
        color: inherit;
        text-decoration: none;
    }
}
strong{
    font-weight: bold;
}
small{
    font-size: 12px;
}
input{
    color: $dark;
}
select{
    color: $dark;
}
textarea{
    color: $dark;
}
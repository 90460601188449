//banner
.vdr_banner{
    color: $white;
    padding: 0;
    @include bgImgCenterCover;
    position: relative;
    min-height: 200px;
    @media (min-width: $laptopMin){
        min-height: 250px;
    } 
    h2{
        margin-bottom: 0;
    }
    .vdr_banner-container{
        
        position: absolute;
        @include positionCenter;
        .vdr_inner{
            a{
                @include transition(0.15s);
                &:hover{
                    text-decoration: none;
                    color: $themeColor;
                }
            }
        }    
           
    }
}

.vdr_under-banner-section{   
    @media(min-width: $laptopMin){
        padding-top: 0;
        margin-top: -68px;
    }
}

//homepage slider
.vdr_homepage-slider{
    img{
        width: 100%;
    }
    .slick-dots {
        bottom: 25px;
        button{
            &:before{
                font-size: 14px;
                color: $themeColor;
            }
        }
        &.slick-active{
            button{
                &:before{
                    color: $themeColor;
                }
            }
        }
    }
    .vdr_arrow-next{
        position: absolute;
        right: 0;
        padding: 20px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        cursor: pointer;
        img{
            width: 30px;
        }
    }
    .vdr_arrow-prev{
        position: absolute;
        left: 0;
        padding: 20px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        cursor: pointer;
        img{
            width: 30px;
        }
    }
    
    .vdr_slider-item{
        position: relative;
    }
    .vdr_slide-article{
        @include positionCenter;
        text-align: center;
        width: 80%;
        h1{
            color: $white;
            margin: 20px 0 30px 0;
            font-weight: 700;
        }
        p{
            color: $themeColor;
            margin-bottom: 0;
        }
    }
}

.vdr_about-section{
    h4{
        color: $dark;
    }
    h2{
        color: $dark;
    }
    p{
        color: $dark;
    }
    ul{
        padding-left: 0;
        li{
            color: $dark;
            list-style: none;
            display: flex;
            padding: 5px 0;
        }
    }
    img{
        width: 100%;
    }
}
.vdr_top-sub-heading{
    color: $themeColor!important;
    font-weight:300;
    margin: 0;
    text-transform: uppercase;
}
.vdr_signature-row{
    // text-align: center;
    .vdr_grid{
        padding: 15px 10px;
    }
    .border-left{
        @media (max-width: $laptopMax){
            border-left: none!important;
        }
    }
}

.vdr_our-solutions-section{
    @include bgImgCenterCover;
    text-align: center;
    h2{
        color: $white;
    }
}

.vdr_ourteam-section{
    text-align: center;

    h2{
        color: $dark;
    }
}

.vdr_testimonial-section{
    background-color: $themeColor;
    color: $white;
    @include bgImgCenterCover;
    .vdr_slide{
        text-align: center;
        img{
            width: 120px;
            height: 120px;
            border-radius: 50%;
            margin-bottom: 20px;
            margin: 20px auto;
        }
        .vdr_italic{
            font-style: italic;
        }
    }
}

.vdr_lets-start-a-conversation-form{
    h2{
        color: $dark;
        text-align: center;
    }
    .hor_line{
        margin: 0 auto;
    }
    .vdr_submit-button{
        margin: 0 auto;
    }
}

.vdr_whychooseus-section{
    @include bgImgCenterCover;
    h2{
        color: $white;
    }
    h3{
        color: $white;
    }
    p{
        color: $white;
    }
    .vdr_row-wcu-item{
        border-bottom: 1px solid $light;
        margin: 0;
        align-items: center;
        display: flex;
        padding: 30px;
        &:last-child{
            border-bottom: none;
            margin-bottom: 0;
        }
    }
    .vdr_col-right{
        .vdr_inner{
            p{
                margin: 0;
            }
            @media (min-width: $tabletMin){
                border-left: 1px solid $light;
                border-bottom: none;
                padding-left: 40px;
            }
        }
    }
    .vdr_row{
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 20px;
        .vdr_item{
            img{
                width: 100%;
            }
            h3{
                margin-bottom: 0;
            }
            &:nth-of-type(1){
                width: 75px;
                margin-right: 20px;
            }
        }
    }
}
.vdr_solutions-section{
    text-align: center;
    h2{
        color: $dark;
    }
}

.vdr_googlemaps-section{
    height: 400px;
    margin-bottom: 50px;
    .vdr_col{
        height: 400px;
        overflow: hidden;
    }
    @media(min-width: $tabletMin){
        margin-bottom: 75px;
    }
    @media(min-width: $laptopMin){
        margin-bottom: 100px;
    }
}
.parallax-window {
    min-height: 400px;
    background: transparent;
}

.vdr_faq-section{
    h2{
        color: $white;
    }
}
.vdr_image-text-section {
    p{
        color: $dark;
    }
}
header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;    
    background-color: $white;
    z-index: 2;
    .vdr_header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 94px;
        position: relative;
        @include expandRow;
    }
    @media (min-width: $laptopMin){
        top: 50px;
        .vdr_header{
            height: 120px;
        }
    }
    .icon{
        fill: $dark;
    }
}

footer{
    background-color: $dark;
    color: $white;
    padding: 50px 0 30px 0;
    @media(min-width: $tabletMin){
        padding: 75px 0 30px 0;
    }
    @media(min-width: $laptopMin){
        padding: 100px 0 30px 0;
    }
}

section{
    padding: 50px 0;
    @media(min-width: $tabletMin){
        padding: 75px 0;
    }
    @media(min-width: $laptopMin){
        padding: 100px 0;
    }
    .vdr_top-sub-heading{
        margin: 0 0 10px 0;
    }
    h2{
        margin: 0 0 30px 0;
    }
    h1{
        margin: 0 0 30px 0;
    }
    .hor_line{
        margin: 0 auto 50px auto;
    }
}

.vdr_menu_widescreen{
    display: none;
    @media (min-width: $laptopMin){
        display:flex;
        margin: 0;
        padding: 0;
        li{
            list-style: none;
            font-size: 20px;
            text-transform: uppercase;
            font-weight: 700;
            color: $dark;
            a{
                padding: 34px 15px;
                @include transition(0.15s);
                &:hover{
                    text-decoration: none;
                    color: $themeColor;
                }
            }            
        }
    }
}

.vdr_menuIcon{
    padding: 20px 15px;
    @media (min-width: $laptopMin){
        display:none;
    }
}

.vdr_menu_mobile{
    z-index: 1000;
    background-color: $white;
    color: $dark;
    height: 100vh;
    position: fixed;
    width: 100%;
    left: 0;
    top: 85px;
    @include transition(0.25s);
    &.open{
        opacity: 1;
        visibility: visible;
    }
    &.closed{
        opacity: 0;
        visibility:hidden;
        left: -100%;
    }
    @media (min-width: $laptopMin){
        display:none;
    }
    ul{
        padding: 25px;
        li{
            color: $dark;
            font-size: 30px;
            font-weight: 700;
            border-bottom: 1px solid $veryLight;
            list-style: none;
            padding: 25px 0;
            text-transform: uppercase;
            a{
                padding: 25px 0;
                &:hover{
                    text-decoration: none;
                }
            }
        }
    }
    .vdr_copyright-smalltext{
        position: absolute;
        bottom: 100px;
        padding: 20px 25px;
    }
}

.vdr_page-content{
    margin-top: 100px;
    min-height: 55vh;
    @media (min-width: $laptopMin){
        margin-top: 170px;
    }
}

.vdr_topbar{
    display:none;
    z-index: 2;
    @media (min-width: $laptopMin){
        display:block;
        background-color: $dark;
        width: 100%;
        position: fixed;
        top:0;
        left:0;
        .vdr_topbar-inner{
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include expandRow;
        }
        .vdr_topbar-right{
            display: flex;
        }
        .vdr_topbar-left{
            display: flex;
        }
        small{
            color: $white;
            padding: 5px 15px;
            margin: 0;
            display:flex;
            justify-content: space-between;
            align-items: center;
            img{
                margin-right: 5px;
            }
        }
    }
}

.vdr_logo{
    padding: 20px 15px;
    color: $themeColor;
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    
    @media (max-width: $laptopMax){
        @include positionCenter;
    }
    
    img{
        width: 180px;
        @media (min-width: $laptopMin){
            width: 255px;
        }
    }
}

.vdr_bottom-bar{
    background-color: $veryDark;
    color: $white;
    .vdr_inner{
        text-align: center;
        padding: 15px 0;
    }
}

.vdr_card{
    border-radius: 0;
    margin-bottom: 30px;
    h4{
        text-transform: uppercase;
        padding: 20px 10px;
        margin-bottom: 0;
        text-align: center;
        color: $dark;
        @include transition(0.25s);
    }
    p{
        color: $dark;
        padding: 20px 20px 0 20px;
        text-align: center;
        margin-bottom: 0;        
        @media (min-width: $tabletMin){
            // height: 170px;
        }
    }
    .vdr_readmore-button{
        margin: 0 auto;
        padding: 20px;        
    }
    &:hover{
        .vdr_readmore-button{
            color: $themeColor;
            text-decoration: underline;
        }            
    }
    img{
        @include transition(0.25s);
    }
    &:hover{
        h4{
            color: $themeColor;
        }
        img{
            @include scale(1.25)
        }
    }
}
.vdr_card-2{
    background-color: transparent;
    border: 1px dashed $white;
    padding: 20px;
    color: white;
    margin-bottom: 30px;
    border-radius:0;
    @include transition(0.25s);

    img{
        margin: 20px 0;  
        @include transition(0.25s);      
    }
    
    .vdr_readmore-button{
        color: $white;
        &:hover{
             color: $themeColor;
             text-decoration: underline;
        }
    }

    &:hover{
        img{
            @include scale(1.25);
        }
        border: 1px dashed $themeColor;
    }
    @media (min-width: $laptopMin){
        .vdr_img_cont{
            height: 140px;
            display: flex;
            justify-content: center;
            align-content: center;
        }
    }
}
.vdr_card-3{
    border: none;
    border-radius: 0;
    padding: 0;
    margin: 0 15px 15px 15px;
    background-color: #FAFAFA;
    img{
        width: 100%;
    }
    h4{
        color: $dark;
        margin-top: 30px;
    }
    p{
        color: $dark;
    }
}
.vdr_card-4{
    border: 1px dashed $themeColor;
    padding: 20px;
    color: $dark;
    margin-bottom: 30px;
    border-radius:0;
    @include transition(0.25s);    

    .vdr_img_cont{
        position: relative;
        height: 140px;
    }

    .vdr_white{
        opacity: 0;
        @include transition(0.25s);
        visibility: hidden;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
    .vdr_green{
        opacity: 1;
        @include transition(0.25s);
        visibility: visible;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }

    img{
        margin: 20px 0;  
        height: 100px;
        @include transition(0.25s);   
           
    }

    // @media (min-width: $tabletMin){
    //     h3{
    //         height: 50px;
    //     }
    //     p{
    //         height: 130px;
    //     }
    //     img{
    //         height: 80px;
    //     }
    // }
    
    
    &:hover{
        background-color: $themeColor;
        color: $white;
        img{
            @include scale(1.25);
        }
        .vdr_green{
            opacity: 0;
            visibility: hidden;
        }
        .vdr_white{
            opacity: 1;
            visibility: visible;
        }
        .vdr_readmore-button{
            color: $white;     
            text-decoration: underline;   
        }
    }
}
.vdr_card-img-container{
    width: 100%;
    overflow: hidden;
}
.vdr_readmore-button{
    text-transform: uppercase;
    font-weight: 700;
    color: $dark;
    text-decoration: underline;
    @include transition(0.15s);
}

.vdr_row{
    justify-content: center;
}
.vdr_row{
    display: flex;
}
.vdr_form{
    color: $dark;
    .vdr_input{
        width: 100%;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px solid $veryLight;
        padding: 10px 0;
        margin: 10px 0;
        background-color: transparent;
        @media (min-width: $tabletMin){
            margin: 20px 0;
        }
        @media (min-width: $laptopMin){
            margin: 30px 0;
        }
        &::placeholder{
            color: $dark;
            opacity: 1;
        }
    }
}

.vdr_getintouch-section{
    h2{
        color: $dark;
    }
    h3{
        color: $dark;
        border-bottom: 1px solid $veryLight;
        margin-bottom: 15px;
        padding-bottom: 15px;
    }
    p{
        color: $dark;
    }
    .hor_line{
        margin-left: 0;
    }
    .vdr_lets-start-column{
        @include bgImgCenterCover;
        margin-top: 50px;
        @media (min-width: $tabletMin){
            margin-top: -150px;
        }
        @media (min-width: $laptopMin){
            margin-top: -200px;
        }
    }
    .vdr_lets-start-a-conversation-form{
        color: $white;
        .vdr_input{
            border-bottom: 1px solid $white;
            &::placeholder{
                color: $white;
            }
            color: $white;
        }      
        .vdr_theme-button{
            background-color: $dark;
            &:hover{
                background-color: $white;
                color: $themeColor;
            }
        }  
        h2{
            color: $white;
        }
        
    }
}
@mixin transition($time){
    transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    -moz-transition-timing-function: ease-in-out;
    -ms-transition-timing-function: ease-in-out;
    transition-duration: $time;
    -webkit-transition-duration: $time;
    -moz-transition-duration: $time;
    -ms-transition-duration: $time;
    transition-delay: 0.25s;
    -webkit-transition-delay: 0.25s;
    -moz-transition-delay: 0.25s;
    -ms-transition-delay: 0.25s;
}

@mixin expandRow{
    margin-left: -15px;
    margin-right: -15px;
}

@mixin positionCenter {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

@mixin positionCenterLeft {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-37%);
}

@mixin bgImgCenterCover{
    background-position: center;
    background-size: cover;
}

@mixin scale($ratio){
    transform: scale($ratio);
    -ms-transform: scale($ratio);
    -moz-transform: scale($ratio);
    -webkit-transform: scale($ratio);
}
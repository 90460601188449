.hor_line{
    width: 50px;
    height: 3px;
    background-color: $themeColor;
    margin-bottom: 25px;
}

.vdr_theme-button{
    background-color: $themeColor;
    border-radius: 25px;
    border: none;
    color: $white;
    padding: 12px 30px 10px 30px;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    border: 2px solid $themeColor;
    &:hover{
        background-color: $white;
        color: $themeColor;
        text-decoration: none;
        @include transition(0.25s);
    }
}

.vdr_signature{
    width: 150px!important;
}

.vdr_heading-section{
    color: $dark;
    text-align: center;
    padding-bottom: 20px;
}
.vdr_content-section{
    color: $dark;
    padding: 20px 0 20px 0;
    h2{
        font-size: 25px;
        @media (min-width: $laptopMin){
            font-size: 30px;
        }
    }
}
.vdr_green-text{
    color: $themeColor;
    margin-bottom: 30px;
}
.vdr_image-text-section{
    padding: 20px 0 20px 0;
    figure{
        img{
            width: 100%;
        }
    }
    &.switch{
        .vdr_col-left{
            @media (min-width: $tabletMin){
                order: 2;
            }
        }
    }
}
.vdr_bg-image-text-section{
    color: $white;
    h2{
        font-size: 25px;
        @media (min-width: $laptopMin){
            font-size: 30px;
        }
    }
}

.vdr_popup-wrapper{
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $white;
    .vdr_popup-inner{
        @include positionCenter;
    }
    h2{
        margin-bottom: 30px;
    }
    .hor_line{
        margin-left: auto;
        margin-right: auto;
    }
}